<template>
  <div class="home">
    <v-layout row wrap>
      <v-flex xs12 md12 lg12 class="mb-5">
        <h1 class="f-alfa primary--text">
          <router-link to="/">Pro Track</router-link> /
          <router-link to="/accounts">Accounts</router-link> /
          {{ account.name }}
        </h1>
      </v-flex>
      <v-flex xs12 md12 lg8>
        <v-simple-table>
          <tbody>
            <tr>
              
              <th class="fs-12 primary--text fw-bold">
                <div
                  style="
                    width: 200px;
                    height: 200px;
                    background-color: #212121;
                  "
                  class="mt-5 mb-5"
                >
                  <img
                    :src="account.display_picture"
                    style="width: 100%; height: 100%; object-fit: cover"
                    v-if="account.display_picture"
                  />
                  <div
                    class="grey darken-3"
                    v-else
                    style="width: 100%; height: 100%"
                  ></div>
                </div>
              </th>
              <th class="fs-12 primary--text fw-bold"></th>
            </tr>
            <tr>
              <th class="fs-12 primary--text fw-bold">Name</th>
              <th class="fs-12">{{ account.name }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text fw-bold">Email</th>
              <th class="fs-12">{{ account.email }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text fw-bold">Phone</th>
              <th class="fs-12">{{ account.phone }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text fw-bold">Department</th>
              <th class="fs-12">
                <!-- {{ account.department.name }} -->
              </th>
            </tr>
            <tr>
              <th class="fs-12 primary--text fw-bold">Role</th>
              <th class="fs-12">{{ account.role }}</th>
            </tr>
            <tr>
              <th class="fs-12 primary--text fw-bold">Reporting To</th>
              <th class="fs-12">
                <span v-if="account.reporting_to">{{
                  account.reporting_to.name
                }}</span>
              </th>
            </tr>
            <tr>
              <th class="fs-12 primary--text fw-bold">Options</th>
              <th class="fs-12">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      class="white--text"
                      light
                      v-bind="attrs"
                      v-on="on"
                    >
                      Manage Account <span><v-icon>mdi-menu-down</v-icon></span>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title @click="editAccount" style="cursor: pointer;"
                        >Edit Account</v-list-item-title
                      >
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title style="cursor: pointer;" @click="resetPassword">Reset Password</v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title style="cursor: pointer;">Delete Account</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </th>
            </tr>
          </tbody>
        </v-simple-table>
      </v-flex>
    </v-layout>
  </div>
</template>
  
  <script>
import { BASE_URL } from "@/config";
import Axios from "axios";

export default {
  data() {
    return {
      account: [],
    };
  },
  mounted() {
    this.fetchAccount();
  },
  methods: {
    async fetchAccount() {
      let url = BASE_URL + "/user/" + this.$route.params.id;
      let { data } = await Axios.get(url);
      this.account = data;
    },
    editAccount() {
      this.$router.push({
        name: "EditAccount",
        params: { id: this.$route.params.id },
      });
    },
    resetPassword(){
      this.$router.push({
        name : "ResetPassword",
        params : {id : this.$route.params.id}
      })
    }
  },
};
</script>
  
    <style scoped>
.fs-12 {
  font-size: 12pt !important;
  font-weight: 400;
}
</style>